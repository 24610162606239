import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import ProjectCard from "./ProjectCards";
import Particle from "../Particle";
// import Converter from "../../Assets/Projects/project1.png";
// import Manager from "../../Assets/Projects/project2.png";
import Crypto from "../../Assets/Projects/project3.png";
import GetID from "../../Assets/Projects/project4.png";
import VideoBot from "../../Assets/Projects/project6.png";
import Valentine from "../../Assets/Projects/project5.png";


function Projects() {
  return (
    <Container fluid className="project-section">
      <Particle />
      <Container>
        <h1 className="project-heading">
          My Recent <strong className="purple">Works </strong>
        </h1>
        <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>
          {/* <Col md={5} className="project-card">
            <ProjectCard
              imgPath={Converter}
              isBlog={false}
              title="💱Currency Converter"
              description="This project is a Currency Converter web application developed using Python and Flask. 
              The primary purpose of the application is to enable users to perform 
              real-time currency conversions based on up-to-date exchange rates."
              ghLink="https://github.com/bohd4nx/Currency-Converter"
            />
          </Col>

          <Col md={5} className="project-card">
            <ProjectCard
              imgPath={Manager}
              isBlog={false}
              title="📑Task Manager"
              description="This project is a Task Manager web application built using Python and Flask framework,
               designed to manage tasks by adding, completing, and deleting them. 
               The application also generates an Excel report of the tasks."
              ghLink="https://github.com/bohd4nx/Task-Manager"
            />
          </Col>


          <h1 className="project-heading">
          <strong className="purple">Bots </strong>
        </h1> */}


          <Col md={5} className="project-card">
            <ProjectCard
              imgPath={Crypto}
              title="💲Crypto Tracker"
              description="CryptoTracker is a universal tool designed to track cryptocurrency prices on exchanges in real time. 
              With CryptoTracker, users can track cryptocurrency prices on popular exchanges not only through the command line, 
              but also through the Telegram bot."
              ghLink="https://github.com/bohd4nx/crypto-tracker"
              demoLink="https://coinprice.bohd4n.dev/"
            />
          </Col>

          <Col md={5} className="project-card">
            <ProjectCard
              imgPath={VideoBot}
              title="🤖 Video Bot"
              description="This Telegram bot designed to convert videos into video messages. 
              It allows users to send videos to the bot, which then processes them to create video messages suitable for sharing in Telegram chats."
                ghLink="https://github.com/bohd4nx/Telegram-Video-Bot"
            />
          </Col>

          <Col md={5} className="project-card">
              <ProjectCard
                imgPath={GetID}
                title="🆔 Get Media ID"
                description="Get Media ID is a telegram bot that will help you get the ID of a sticker, 
                file or GIF. You can also use it to get your user ID, or to get the ID of other users or channels, chats, etc."
                ghLink="https://github.com/bohd4nx/crypto-tracker"
              />
            </Col>

          {/* Section No. 2 */}
          <h1 className="project-heading">
          Made for <strong className="purple">Fun </strong>
        </h1>
          
          <Col md={5} className="project-card">
            <ProjectCard
              imgPath={Valentine}
              title="❤️ Valentine"
              description="Wish someone a Happy Valentine's Day :)"
              ghLink="https://github.com/7GitGuru/valentine"
              demoLink="https://valentine.bohd4n.dev"              
            />
          </Col>

        </Row>
      </Container>
    </Container>
  );
}

export default Projects;
